// index.js
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './index.css'
import { ChakraProvider } from '@chakra-ui/react'
import ReactGA from "react-ga4";

const root = ReactDOM.createRoot(document.getElementById('root'))


ReactGA.initialize("G-4SC21DJ0M8");

root.render(
  <ChakraProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ChakraProvider>,
)
