import React, { useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import toast, { Toaster } from 'react-hot-toast'
import axios from 'axios'
import { gtag } from 'ga-gtag'

function Contact() {
  // Initialize state for form fields
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    company: '',
    subject: '',
    message: '',
  })

  // Function to handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault()
    if (
      formData.company &&
      formData.email &&
      formData.email.includes('@') &&
      formData.email.includes('.') &&
      formData.message &&
      formData.phoneNumber &&
      formData.subject
    ) {
      console.log(formData) // Log all form field values
      // You can optionally submit the form data to an API or perform other actions here

      try {
        const response = await axios.post(
          'https://export-backend.onrender.com/submit-form',
          formData,
        )
        console.log(response.data)
        toast.success(`Thank you!
                    Your submission has been received!`)

        // Call the conversion function when the form is successfully submitted
        gtag('AW-944866793', {
          send_to: 'k9iFCKGHsKcZEOmLxsID',
        })
      } catch (error) {
        console.error('Error submitting form:', error)
        toast.error(`Oops! 
                  Something went wrong while submitting the form.`)
      }
    } else {
      toast.error(`Oops! 
                  Something went wrong while submitting the form.`)
    }
  }

  return (
    <div>
      <style jsx>{`
        /*! CSS Used from: https://assets.website-files.com/5dd640678990fd9eb30e9f42/css/maritmate-navigations.53a347459.css */
        footer,
        main,
        section {
          display: block;
        }
        a {
          background-color: transparent;
        }
        a:active,
        a:hover {
          outline: 0;
        }
        img {
          border: 0;
        }
        input,
        textarea {
          color: inherit;
          font: inherit;
          margin: 0;
        }
        input {
          line-height: normal;
        }
        textarea {
          overflow: auto;
        }
        * {
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
        }
        img {
          max-width: 100%;
          vertical-align: middle;
          display: inline-block;
        }
        html.w-mod-touch * {
          background-attachment: scroll !important;
        }
        .w-button {
          display: inline-block;
          padding: 9px 15px;
          background-color: #3898ec;
          color: white;
          border: 0;
          line-height: inherit;
          text-decoration: none;
          cursor: pointer;
          border-radius: 0;
        }
        input.w-button {
          -webkit-appearance: button;
        }
        h2,
        h3 {
          font-weight: bold;
          margin-bottom: 10px;
        }
        h2 {
          font-size: 32px;
          line-height: 36px;
          margin-top: 20px;
        }
        h3 {
          font-size: 24px;
          line-height: 30px;
          margin-top: 20px;
        }
        .w-form {
          margin: 0 0 15px;
        }
        .w-form-done {
          display: none;
          padding: 20px;
          text-align: center;
          background-color: #dddddd;
        }
        .w-form-fail {
          display: none;
          margin-top: 10px;
          padding: 10px;
          background-color: #ffdede;
        }
        label {
          display: block;
          margin-bottom: 5px;
          font-weight: bold;
        }
        .w-input {
          display: block;
          width: 100%;
          height: 38px;
          padding: 8px 12px;
          margin-bottom: 10px;
          font-size: 14px;
          line-height: 1.42857143;
          color: #333333;
          vertical-align: middle;
          background-color: #ffffff;
          border: 1px solid #cccccc;
        }
        .w-input:focus {
          border-color: #3898ec;
          outline: 0;
        }
        textarea.w-input {
          height: auto;
        }
        a {
          text-decoration: underline;
        }
        .container {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          width: 100%;
          max-width: 1280px;
          margin-right: auto;
          margin-left: auto;
        }
        .container.vertical {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
        }
        .container.va-bottom {
          -webkit-box-align: end;
          -webkit-align-items: flex-end;
          -ms-flex-align: end;
          align-items: flex-end;
        }
        .container.copyright-container {
          margin-top: 100px;
          -webkit-box-pack: justify;
          -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
        }
        .container.medium {
          max-width: 1055px;
        }
        .section {
          position: relative;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          padding-right: 5%;
          padding-left: 5%;
        }
        .button {
          padding: 18px 34px;
          border-style: solid;
          border-width: 1px;
          border-color: hsla(0, 0%, 100%, 0.4);
          background-color: hsla(0, 0%, 100%, 0);
          -webkit-transition: all 480ms ease;
          transition: all 480ms ease;
          font-family: titling-gothic-fb-compressed, sans-serif;
          font-size: 1em;
          font-weight: 400;
          letter-spacing: 4px;
          text-transform: uppercase;
        }
        .button:hover {
          background-color: hsla(0, 0%, 100%, 0.15);
        }
        .button.huge {
          padding: 45px 120px;
          font-size: 3em;
          letter-spacing: 1px;
        }
        .button.black {
          border-color: #0d1018;
          background-color: #0d1018;
          color: #fff;
        }
        .column {
          position: relative;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          width: 100%;
          padding-right: 0px;
          padding-left: 0px;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: start;
          -webkit-align-items: flex-start;
          -ms-flex-align: start;
          align-items: flex-start;
        }
        .column.c-6 {
          width: 100%;
          max-width: 50%;
        }
        .column.c-4 {
          width: 100%;
          max-width: 33%;
        }
        .heading {
          margin-top: 10px;
          font-family: titling-gothic-fb-compressed, sans-serif;
          font-size: 3em;
          line-height: 1.4;
          font-weight: 400;
          letter-spacing: 2px;
          text-transform: uppercase;
        }
        .heading.huge {
          font-size: 6em;
          line-height: 1;
        }
        .heading.bigger {
          font-size: 6.4em;
          line-height: 1;
          letter-spacing: 0px;
        }
        .heading-section-title-bold {
          font-weight: 400;
        }
        .heading-section-title-light {
          opacity: 0.6;
          font-weight: 200;
        }
        .sales.section {
          min-height: 600px;
          padding-top: 140px;
          padding-bottom: 140px;
          background-color: #0d1018;
          background-image: url('/home_sales.png');
          background-position: 50% 100%;
          background-size: cover;
          background-repeat: no-repeat;
          color: #fff;
        }
        .footer.section {
          padding-top: 60px;
          padding-bottom: 40px;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          background-color: #0d1018;
        }
        .footer-column {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          width: 20%;
          margin-right: auto;
          padding-right: 40px;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: start;
          -webkit-align-items: flex-start;
          -ms-flex-align: start;
          align-items: flex-start;
        }
        .footer-column.big {
          width: 45%;
          margin-right: auto;
        }
        .footer-brand {
          max-width: 127px;
        }
        .footer-address {
          margin-top: 25px;
          color: hsla(0, 0%, 100%, 0.6);
          font-size: 0.875em;
          text-decoration: none;
        }
        .footer-link {
          display: block;
          padding-top: 7px;
          padding-bottom: 7px;
          color: hsla(0, 0%, 100%, 0.6);
          font-size: 0.875em;
          text-decoration: none;
        }
        .footer-inline-link {
          color: #fff;
          text-decoration: none;
        }
        .footer-links {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
        }
        .footer-link-header {
          color: #fff;
          font-size: 1.1em;
          line-height: 1.2;
        }
        .footer-address-text {
          color: hsla(0, 0%, 100%, 0.6);
          text-decoration: none;
        }
        .footer-categories-list {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        }
        .footer-category {
          width: 48%;
        }
        .copyright {
          color: hsla(0, 0%, 100%, 0.6);
          font-size: 0.875em;
        }
        .about-row {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          margin-top: 40px;
          padding-bottom: 100px;
          border-bottom: 1px solid rgba(13, 16, 24, 0.1);
        }
        .contact.section {
          padding-bottom: 100px;
        }
        .input-group {
          position: relative;
          margin-top: 20px;
          padding-right: 17px;
          -webkit-box-flex: 1;
          -webkit-flex: 1;
          -ms-flex: 1;
          flex: 1;
        }
        .inputs-group {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        }
        .input-field {
          min-height: 58px;
          min-width: 300px;
          padding-top: 21px;
          padding-right: 15px;
          padding-left: 15px;
          border-style: solid;
          border-width: 1px;
          border-color: rgba(13, 16, 24, 0.3);
          background-color: transparent;
          font-family: neue-haas-grotesk-text, sans-serif;
          font-size: 1em;
          line-height: 1;
          font-weight: 700;
        }
        .input-field:focus {
          border-color: #0057d9;
          background-color: #fff;
          box-shadow: 0 0 3px 2px rgba(0, 87, 217, 0.17);
        }
        .input-field.textarea {
          min-height: 120px;
          padding-top: 26px;
        }
        .input-label {
          position: absolute;
          left: 16px;
          top: 10px;
          right: auto;
          bottom: auto;
          opacity: 0.8;
          color: #0d1018;
          font-size: 0.7rem;
        }
        .form-block {
          width: 100%;
        }
        .contact-banner.section {
          padding-top: 220px;
        }
        .success-message {
          padding-top: 40px;
          padding-bottom: 40px;
          background-color: #0d1018;
          font-family: titling-gothic-fb-compressed, sans-serif;
          color: #fbfcfe;
          font-size: 4em;
          font-weight: 200;
          letter-spacing: 2px;
          text-transform: uppercase;
        }
        .text-block {
          line-height: 1;
        }
        .error-message {
          padding: 18px;
          border-style: solid;
          border-width: 2px;
          border-color: red;
          background-color: #fdd;
          color: #b9171a;
          font-size: 1.2em;
          font-weight: 700;
        }
        @media (max-width: 991px) {
          .container {
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
          }
          .button.huge {
            padding: 30px 64px;
            font-size: 2em;
          }
          .column.c-6 {
            max-width: none;
          }
          .column.c-4 {
            max-width: none;
          }
          .heading.huge {
            font-size: 4em;
          }
          .heading.bigger {
            font-size: 5em;
          }
          .footer-column {
            width: 100%;
          }
        }
        @media (max-width: 767px) {
          .heading.huge {
            font-size: 3.4em;
          }
          .heading.bigger {
            font-size: 4em;
          }
          .sales.section {
            min-height: 0px;
            padding-top: 81px;
            padding-bottom: 81px;
          }
          .footer-category {
            width: 100%;
          }
          .about-row {
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
          }
          .input-group {
            width: 100%;
            padding-right: 0px;
            -webkit-box-flex: 0;
            -webkit-flex: 0 auto;
            -ms-flex: 0 auto;
            flex: 0 auto;
          }
          .input-field {
            min-width: 0px;
            font-size: 0.9em;
          }
          .success-message {
            font-size: 3em;
          }
        }
        @media (max-width: 479px) {
          .container.va-bottom {
            -webkit-align-content: flex-end;
            -ms-flex-line-pack: end;
            align-content: flex-end;
          }
          .container.copyright-container {
            margin-top: 40px;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: start;
            -webkit-align-items: flex-start;
            -ms-flex-align: start;
            align-items: flex-start;
          }
          .button.huge {
            padding: 14px 37px;
            font-size: 1.4em;
          }
          .heading.huge {
            font-size: 2.6em;
          }
          .heading.bigger {
            font-size: 4em;
          }
          .sales.section {
            min-height: 380px;
          }
          .footer-column {
            margin-bottom: 40px;
          }
          .contact-banner.section {
            padding-top: 200px;
            padding-bottom: 40px;
          }
          .success-message {
            font-size: 2em;
          }
        }
        /*! CSS Used from: Embedded */
        * {
          -webkit-font-smoothing: antialiased;
        }
        /*! CSS Used fontfaces */
        @font-face {
          font-family: titling-gothic-fb-compressed;
          src: url(https://use.typekit.net/af/e453b9/00000000000000003b9ae8e3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3)
              format('woff2'),
            url(https://use.typekit.net/af/e453b9/00000000000000003b9ae8e3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3)
              format('woff'),
            url(https://use.typekit.net/af/e453b9/00000000000000003b9ae8e3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3)
              format('opentype');
          font-weight: 200;
          font-style: normal;
          font-stretch: normal;
          font-display: auto;
        }
        @font-face {
          font-family: titling-gothic-fb-compressed;
          src: url(https://use.typekit.net/af/53cee3/00000000000000003b9ae8e4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3)
              format('woff2'),
            url(https://use.typekit.net/af/53cee3/00000000000000003b9ae8e4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3)
              format('woff'),
            url(https://use.typekit.net/af/53cee3/00000000000000003b9ae8e4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3)
              format('opentype');
          font-weight: 300;
          font-style: normal;
          font-stretch: normal;
          font-display: auto;
        }
        @font-face {
          font-family: titling-gothic-fb-compressed;
          src: url(https://use.typekit.net/af/ea98f6/00000000000000003b9ae8e5/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
              format('woff2'),
            url(https://use.typekit.net/af/ea98f6/00000000000000003b9ae8e5/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
              format('woff'),
            url(https://use.typekit.net/af/ea98f6/00000000000000003b9ae8e5/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
              format('opentype');
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          font-display: auto;
        }
        @font-face {
          font-family: neue-haas-grotesk-text;
          src: url(https://use.typekit.net/af/abbb5b/00000000000000003b9b2054/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
              format('woff2'),
            url(https://use.typekit.net/af/abbb5b/00000000000000003b9b2054/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
              format('woff'),
            url(https://use.typekit.net/af/abbb5b/00000000000000003b9b2054/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
              format('opentype');
          font-weight: 700;
          font-style: normal;
          font-stretch: normal;
          font-display: auto;
        }
        @font-face {
          font-family: neue-haas-grotesk-text;
          src: url(https://use.typekit.net/af/73eec9/00000000000000003b9b2055/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
              format('woff2'),
            url(https://use.typekit.net/af/73eec9/00000000000000003b9b2055/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
              format('woff'),
            url(https://use.typekit.net/af/73eec9/00000000000000003b9b2055/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
              format('opentype');
          font-weight: 700;
          font-style: italic;
          font-stretch: normal;
          font-display: auto;
        }
      `}</style>
      <Header />
      <main id="main" className="main">
        <div className="contact-banner section">
          <div className="container medium">
            <div className="column c-4">
              <div className="section-title">
                <h2 className="heading bigger">
                  <span className="heading-section-title-light">
                    Get in touch
                    <br />‍
                  </span>
                  <span className="heading-section-title-bold">With us</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <section id="who-we-are" className="contact section">
          <div className="container medium vertical">
            <div className="about-row">
              <div className="form-block w-form">
                <form
                  id="wf-form-Contact-Form"
                  name="wf-form-Contact-Form"
                  data-name="Contact Form"
                  method="post"
                  data-wf-page-id="5ddc9689e0447d397f75efe0"
                  data-wf-element-id="68db60c0-275b-867b-ba63-686dd8367280"
                  onSubmit={handleSubmit}
                >
                  <div className="inputs-group">
                    <div className="input-group">
                      <input
                        className="input-field w-input"
                        maxLength={256}
                        name="name"
                        data-name="Name"
                        placeholder="Enter your full name"
                        type="text"
                        id="name"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="name" className="input-label">
                        Full name
                      </label>
                    </div>
                    <div className="input-group">
                      <input
                        className="input-field w-input"
                        maxLength={256}
                        name="email"
                        data-name="Email"
                        placeholder="Enter your e-mail address"
                        type="email"
                        id="email"
                        required=""
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="email" className="input-label">
                        Email Address
                      </label>
                    </div>
                  </div>
                  <div className="inputs-group">
                    <div className="input-group">
                      <input
                        className="input-field w-input"
                        maxLength={256}
                        name="phoneNumber"
                        data-name="Phone-Number"
                        placeholder="Enter your phone number"
                        type="number"
                        id="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="name-3" className="input-label">
                        Phone number
                      </label>
                    </div>
                    <div className="input-group">
                      <input
                        className="input-field w-input"
                        maxLength={256}
                        name="company"
                        data-name="company"
                        placeholder="Enter your company name"
                        type="text"
                        id="company"
                        value={formData.company}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="name-3" className="input-label">
                        Company
                      </label>
                    </div>
                  </div>
                  <div className="input-group">
                    <input
                      className="input-field w-input"
                      maxLength={256}
                      name="subject"
                      data-name="Name 2"
                      placeholder="Enter the subject of conversation"
                      type="text"
                      id="subject"
                      value={formData.subject}
                      onChange={handleInputChange}
                    />
                    <label htmlFor="name-3" className="input-label">
                      Subject
                    </label>
                  </div>
                  <div className="input-group">
                    <textarea
                      placeholder="Enter your message here"
                      maxLength={5000}
                      id="message"
                      name="message"
                      data-name="message"
                      className="input-field textarea w-input"
                      defaultValue={''}
                      value={formData.message}
                      onChange={handleInputChange}
                    />
                    <label htmlFor="name-3" className="input-label">
                      Message
                    </label>
                  </div>
                  <input
                    type="submit"
                    data-wait="Sending message..."
                    className="button black w-button"
                    defaultValue="Submit your message"
                  />
                </form>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
      <div>
        <Toaster />
      </div>
    </div>
  )
}

export default Contact
