import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'

function About() {
  return (
    <div>
      <style jsx>{`
        /*! CSS Used from: https://assets.website-files.com/5dd640678990fd9eb30e9f42/css/maritmate-navigations.53a347459.css */
        footer,
        main,
        section {
          display: block;
        }
        a {
          background-color: transparent;
        }
        a:active,
        a:hover {
          outline: 0;
        }
        h1 {
          font-size: 2em;
          margin: 0.67em 0;
        }
        img {
          border: 0;
        }
        * {
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
        }
        img {
          max-width: 100%;
          vertical-align: middle;
          display: inline-block;
        }
        .w-inline-block {
          max-width: 100%;
          display: inline-block;
        }
        .w-button {
          display: inline-block;
          padding: 9px 15px;
          background-color: #3898ec;
          color: white;
          border: 0;
          line-height: inherit;
          text-decoration: none;
          cursor: pointer;
          border-radius: 0;
        }
        h1,
        h2,
        h3 {
          font-weight: bold;
          margin-bottom: 10px;
        }
        h1 {
          font-size: 38px;
          line-height: 44px;
          margin-top: 20px;
        }
        h2 {
          font-size: 32px;
          line-height: 36px;
          margin-top: 20px;
        }
        h3 {
          font-size: 24px;
          line-height: 30px;
          margin-top: 20px;
        }
        p {
          margin-top: 0;
          margin-bottom: 10px;
        }
        .w-embed:before,
        .w-embed:after {
          content: ' ';
          display: table;
          grid-column-start: 1;
          grid-row-start: 1;
          grid-column-end: 2;
          grid-row-end: 2;
        }
        .w-embed:after {
          clear: both;
        }
        a {
          text-decoration: underline;
        }
        .container {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          width: 100%;
          max-width: 1280px;
          margin-right: auto;
          margin-left: auto;
        }
        .container.vertical {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
        }
        .container.va-bottom {
          -webkit-box-align: end;
          -webkit-align-items: flex-end;
          -ms-flex-align: end;
          align-items: flex-end;
        }
        .container.copyright-container {
          margin-top: 100px;
          -webkit-box-pack: justify;
          -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
        }
        .container.medium {
          max-width: 1055px;
        }
        .section {
          position: relative;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          padding-right: 5%;
          padding-left: 5%;
        }
        .button {
          padding: 18px 34px;
          border-style: solid;
          border-width: 1px;
          border-color: hsla(0, 0%, 100%, 0.4);
          background-color: hsla(0, 0%, 100%, 0);
          -webkit-transition: all 480ms ease;
          transition: all 480ms ease;
          font-family: titling-gothic-fb-compressed, sans-serif;
          font-size: 1em;
          font-weight: 400;
          letter-spacing: 4px;
          text-transform: uppercase;
        }
        .button:hover {
          background-color: hsla(0, 0%, 100%, 0.15);
        }
        .button.huge {
          padding: 45px 120px;
          font-size: 3em;
          letter-spacing: 1px;
        }
        .column {
          position: relative;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          width: 100%;
          padding-right: 0px;
          padding-left: 0px;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: start;
          -webkit-align-items: flex-start;
          -ms-flex-align: start;
          align-items: flex-start;
        }
        .column.c-6 {
          width: 100%;
          max-width: 50%;
        }
        .column.c-5 {
          width: 100%;
          max-width: 41%;
        }
        .column.offset-1 {
          margin-left: 8%;
        }
        .column.c-4 {
          width: 100%;
          max-width: 33%;
        }
        .column.c-3 {
          max-width: 25%;
        }
        .heading {
          margin-top: 10px;
          font-family: titling-gothic-fb-compressed, sans-serif;
          font-size: 3em;
          line-height: 1.4;
          font-weight: 400;
          letter-spacing: 2px;
          text-transform: uppercase;
        }
        .heading.huge {
          font-size: 6em;
          line-height: 1;
        }
        .heading.big {
          font-size: 4.5em;
          line-height: 1;
        }
        .heading.big.section-title-heading {
          font-weight: 200;
        }
        .heading.big.section-title-heading.margin-top {
          margin-top: 30px;
        }
        .heading.smaller {
          margin-bottom: 5px;
          font-size: 1.8em;
          line-height: 1;
          font-weight: 300;
          letter-spacing: 0px;
        }
        .heading.bigger {
          font-size: 6.4em;
          line-height: 1;
          letter-spacing: 0px;
        }
        .link-block {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          padding: 10px;
          -webkit-box-pack: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          color: #fff;
          line-height: 1;
          text-decoration: none;
        }
        .link-block.dark {
          color: #0d1018;
        }
        .heading-section-title-bold {
          font-weight: 400;
        }
        .text {
          opacity: 0.6;
        }
        .text.margin-top {
          margin-top: 20px;
        }
        .text.big {
          opacity: 1;
          font-size: 1.2em;
        }
        .background {
          position: absolute;
          left: 0%;
          top: 0%;
          right: 0%;
          bottom: 0%;
          overflow: hidden;
        }
        .background.team-bg {
          max-height: 720px;
          max-width: 38vw;
          margin-top: auto;
        }
        .background-image {
          position: absolute;
          left: 0%;
          top: 0%;
          right: 0%;
          bottom: 0%;
        }
        .background-image.team-bg-image {
          background-image: url('https://assets.website-files.com/5dd640678990fd9eb30e9f42/5dd9e722578bab847320f34b_team--bg%402x.jpg');
          background-position: 50% 50%;
          background-size: cover;
          background-repeat: no-repeat;
        }
        .heading-section-title-light {
          opacity: 0.6;
          font-weight: 200;
        }
        .team.section {
          padding-top: 140px;
          padding-bottom: 220px;
        }
        .arrow-side {
          margin-left: 10px;
          -webkit-transform: rotate(270deg);
          -ms-transform: rotate(270deg);
          transform: rotate(270deg);
        }
        .arrow-side.inverted {
          -webkit-filter: invert(100%);
          filter: invert(100%);
        }
        .team-members {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          width: 100%;
        }
        .team-members-list {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          width: 100%;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        }
        .team-member {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          width: 50%;
          margin-bottom: 60px;
          padding-right: 40px;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: start;
          -webkit-align-items: flex-start;
          -ms-flex-align: start;
          align-items: flex-start;
        }
        .team-member-name {
          margin-top: 0px;
          margin-bottom: 0px;
          font-family: titling-gothic-fb-compressed, sans-serif;
          font-size: 1.8em;
          line-height: 1;
          font-weight: 300;
          text-transform: uppercase;
        }
        .team-member-job {
          opacity: 0.6;
          font-size: 0.875em;
        }
        .team-member-info {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          width: 100%;
          margin-top: 20px;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
        }
        .team-member-link {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          margin-right: 20px;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          color: #0d1018;
          font-size: 0.875em;
          text-decoration: none;
          text-transform: lowercase;
        }
        .team-member-link-icon {
          margin-right: 10px;
        }
        .team-member-link-text {
          margin-bottom: 2px;
          opacity: 0.6;
        }
        .team-member-links {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          width: 100%;
          margin-top: 5px;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
        }
        .sales.section {
          min-height: 600px;
          padding-top: 140px;
          padding-bottom: 140px;
          background-color: #0d1018;
          background-image: url('/home_sales.png');
          background-position: 50% 100%;
          background-size: cover;
          background-repeat: no-repeat;
          color: #fff;
        }
        .footer.section {
          padding-top: 60px;
          padding-bottom: 40px;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          background-color: #0d1018;
        }
        .footer-column {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          width: 20%;
          margin-right: auto;
          padding-right: 40px;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: start;
          -webkit-align-items: flex-start;
          -ms-flex-align: start;
          align-items: flex-start;
        }
        .footer-column.big {
          width: 45%;
          margin-right: auto;
        }
        .footer-brand {
          max-width: 127px;
        }
        .footer-address {
          margin-top: 25px;
          color: hsla(0, 0%, 100%, 0.6);
          font-size: 0.875em;
          text-decoration: none;
        }
        .footer-link {
          display: block;
          padding-top: 7px;
          padding-bottom: 7px;
          color: hsla(0, 0%, 100%, 0.6);
          font-size: 0.875em;
          text-decoration: none;
        }
        .footer-inline-link {
          color: #fff;
          text-decoration: none;
        }
        .footer-links {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
        }
        .footer-link-header {
          color: #fff;
          font-size: 1.1em;
          line-height: 1.2;
        }
        .footer-address-text {
          color: hsla(0, 0%, 100%, 0.6);
          text-decoration: none;
        }
        .footer-categories-list {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        }
        .footer-category {
          width: 48%;
        }
        .copyright {
          color: hsla(0, 0%, 100%, 0.6);
          font-size: 0.875em;
        }
        .about-banner.section {
          padding-top: 220px;
          padding-bottom: 60px;
        }
        .about-links {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          margin-top: auto;
          margin-bottom: 16px;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
        }
        .bullets {
          padding-right: 26px;
          padding-left: 26px;
          opacity: 0.6;
        }
        .about-link {
          opacity: 0.6;
          color: #0d1018;
          font-size: 1em;
          text-decoration: none;
        }
        .about-link:hover {
          opacity: 1;
        }
        .about-image {
          min-height: 500px;
          background-color: #0d1018;
          background-image: url('/About.png');
          background-position: 50% 50%;
          background-size: cover;
          background-repeat: no-repeat;
        }
        .about.section {
          padding-top: 100px;
        }
        .about-row {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          margin-top: 40px;
          padding-bottom: 100px;
          border-bottom: 1px solid rgba(13, 16, 24, 0.1);
        }
        .html-embed {
          width: 100%;
          min-height: 400px;
        }
        @media (max-width: 991px) {
          .container {
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
          }
          .button.huge {
            padding: 30px 64px;
            font-size: 2em;
          }
          .column.c-6 {
            max-width: none;
          }
          .column.c-6.offset-1 {
            margin-left: 0%;
          }
          .column.c-5 {
            max-width: none;
          }
          .column.c-5.offset-1 {
            margin-left: 0%;
          }
          .column.c-4 {
            max-width: none;
          }
          .column.c-3 {
            max-width: none;
          }
          .column.c-3.offset-1 {
            margin-left: 0%;
          }
          .heading.huge {
            font-size: 4em;
          }
          .heading.big {
            font-size: 4em;
          }
          .heading.bigger {
            font-size: 5em;
          }
          .background.team-bg {
            position: relative;
            left: auto;
            top: auto;
            right: auto;
            bottom: auto;
            width: 100%;
            max-height: none;
            max-width: 100%;
            min-height: 280px;
          }
          .background-image.team-bg-image {
            background-position: 50% 0%;
            background-size: cover;
          }
          .team.section {
            padding-top: 100px;
            padding-bottom: 100px;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
          }
          .footer-column {
            width: 100%;
          }
          .about-links {
            margin-bottom: 0px;
          }
        }
        @media (max-width: 767px) {
          .column.c-4.offset-1 {
            margin-left: 0%;
          }
          .heading.huge {
            font-size: 3.4em;
          }
          .heading.big {
            font-size: 3em;
          }
          .heading.bigger {
            font-size: 4em;
          }
          .sales.section {
            min-height: 0px;
            padding-top: 81px;
            padding-bottom: 81px;
          }
          .footer-category {
            width: 100%;
          }
          .about-image {
            min-height: 300px;
          }
          .about-row {
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
          }
        }
        @media (max-width: 479px) {
          .container.va-bottom {
            -webkit-align-content: flex-end;
            -ms-flex-line-pack: end;
            align-content: flex-end;
          }
          .container.copyright-container {
            margin-top: 40px;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: start;
            -webkit-align-items: flex-start;
            -ms-flex-align: start;
            align-items: flex-start;
          }
          .button.huge {
            padding: 14px 37px;
            font-size: 1.4em;
          }
          .heading.huge {
            font-size: 2.6em;
          }
          .heading.big {
            font-size: 2.8em;
          }
          .heading.bigger {
            font-size: 4em;
          }
          .background-image {
            background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/example-bg.png');
            background-position: 50% 50%;
            background-size: cover;
            background-repeat: no-repeat;
          }
          .team-member {
            width: 100%;
            margin-bottom: 40px;
          }
          .sales.section {
            min-height: 380px;
          }
          .footer-column {
            margin-bottom: 40px;
          }
          .about-banner.section {
            padding-top: 200px;
            padding-bottom: 40px;
          }
          .about-links {
            margin-top: 20px;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-align: start;
            -webkit-align-items: flex-start;
            -ms-flex-align: start;
            align-items: flex-start;
          }
          .bullets {
            display: none;
          }
          .about-link {
            padding-top: 3px;
            padding-bottom: 3px;
          }
          .about-image {
            min-height: 180px;
          }
        }
        /*! CSS Used from: Embedded */
        * {
          -webkit-font-smoothing: antialiased;
        }
        /*! CSS Used fontfaces */
        @font-face {
          font-family: titling-gothic-fb-compressed;
          src: url(https://use.typekit.net/af/e453b9/00000000000000003b9ae8e3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3)
              format('woff2'),
            url(https://use.typekit.net/af/e453b9/00000000000000003b9ae8e3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3)
              format('woff'),
            url(https://use.typekit.net/af/e453b9/00000000000000003b9ae8e3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3)
              format('opentype');
          font-weight: 200;
          font-style: normal;
          font-stretch: normal;
          font-display: auto;
        }
        @font-face {
          font-family: titling-gothic-fb-compressed;
          src: url(https://use.typekit.net/af/53cee3/00000000000000003b9ae8e4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3)
              format('woff2'),
            url(https://use.typekit.net/af/53cee3/00000000000000003b9ae8e4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3)
              format('woff'),
            url(https://use.typekit.net/af/53cee3/00000000000000003b9ae8e4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3)
              format('opentype');
          font-weight: 300;
          font-style: normal;
          font-stretch: normal;
          font-display: auto;
        }
        @font-face {
          font-family: titling-gothic-fb-compressed;
          src: url(https://use.typekit.net/af/ea98f6/00000000000000003b9ae8e5/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
              format('woff2'),
            url(https://use.typekit.net/af/ea98f6/00000000000000003b9ae8e5/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
              format('woff'),
            url(https://use.typekit.net/af/ea98f6/00000000000000003b9ae8e5/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
              format('opentype');
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          font-display: auto;
        }
      `}</style>
      <Header />
      <main id="main" className="main">
        <div className="about-banner section">
          <div className="container medium">
            <div className="column c-4">
              <div className="section-title">
                <h2 className="heading bigger">
                  <span className="heading-section-title-light">
                    Welcome to
                  </span>
                  <br />
                  <span className="heading-section-title-bold">StallieX</span>
                </h2>
              </div>
            </div>
            <div className="column c-5 offset-1">
              <div className="about-links">
                <a
                  href="#who-we-are"
                  className="about-link w--current"
                  contentEditable="false"
                  style={{ cursor: 'pointer' }}
                >
                  Who we are
                </a>
                <div className="bullets">●</div>
                <a
                  href="#stalliex-in-sweden"
                  className="about-link"
                  contentEditable="false"
                  style={{ cursor: 'pointer' }}
                >
                  StallieX in India
                </a>
                <div className="bullets">●</div>
                {/* <a
                  href="#our-locations"
                  className="about-link"
                  contentEditable="false"
                  style={{ cursor: 'pointer' }}
                >
                  Our locations
                </a> */}
              </div>
            </div>
          </div>
        </div>
        <div className="mx-auto flex justify-center items-center ">
          <img src="/About.png" className='w-[95%] max-w-[70rem]' />
        </div>
        <section id="who-we-are" className="about section">
          <div className="container medium vertical">
            <h2 className="heading smaller">Who we are</h2>
            <div className="about-row">
              <div className="column c-5">
                <p className="text big">
                  StallieX is a leading supplier of passive optical components
                  in India, renowned for our unwavering commitment to
                  reliability and quality. With extensive experience and
                  technical expertise, we have garnered recognition worldwide as
                  a trusted partner in the supply of optical components.
                </p>
              </div>
              <div className="column c-4 offset-1">
                <p className="text">
                  Our network of 25+ manufacturers across India receives
                  comprehensive training and support to ensure exceptional
                  service delivery. Furthermore, we facilitate worldwide service
                  arrangements through strategic partnerships, enabling us to
                  provide seamless support to clients around the globe.
                  <br />
                </p>
                <p className="text margin-top">
                  At StallieX, excellence is not just a goal but a guiding
                  principle. We are dedicated to exceeding expectations in every
                  aspect of our business, from product quality to customer
                  service. Our team of experts works tirelessly to ensure that
                  each client receives personalized attention and solutions
                  tailored to their unique needs. With StallieX, you can trust
                  that you are partnering with a company that prioritizes your
                  success and satisfaction above all else.
                  <br />
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="stalliex-in-sweden" className="about section">
          <div className="container medium vertical">
            <h2 className="heading smaller">StallieX in India</h2>
            <div className="about-row">
              <div className="column c-5">
                <p className="text big">
                  StallieX stands at the forefront of India's optical component
                  industry, renowned for its unwavering commitment to quality
                  and innovation. With a focus on excellence, we continually set
                  new benchmarks and deliver cutting-edge solutions to meet the
                  diverse needs of our customers across the nation.
                </p>
              </div>
              <div className="column c-4 offset-1">
                <p className="text">
                  With a vast network of dealers spanning every corner of India,
                  StallieX brings top-notch optical components and superior
                  service to businesses nationwide. Our local expertise combined
                  with global standards ensures that businesses of all sizes
                  receive tailored solutions to drive their success in today's
                  competitive market.
                  <br />
                </p>
                <p className="text margin-top">
                  Situated in the bustling heart of India, StallieX leverages
                  the country's rich talent pool and resources to make a
                  significant impact on the global stage. Our relentless pursuit
                  of excellence and dedication to quality have earned us
                  recognition worldwide, cementing our position as a trusted
                  partner for businesses seeking to enhance their
                  competitiveness globally.
                  <br />
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* <section id="our-locations" className="about section">
          <div className="container medium vertical">
            <h2 className="heading smaller">Our locations</h2>
            <div className="about-row">
              <div id="html-embed" className="html-embed w-embed w-iframe">
                <iframe
                  src="https://www.google.com/maps/d/embed?mid=1S001bV6j_vogU58cJDIDoy6O1K9xwAEu&hl=en"
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
          </div>
        </section> */}
        <Footer />
      </main>
    </div>
  )
}

export default About
