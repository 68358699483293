import React from 'react'

function Footer() {
  return (
    <div>
      {' '}
      <section id="CTA" className="sales section">
        <div className="container va-bottom">
          <div className="column c-6">
            <h2 className="heading huge">
              <span className="heading-section-title-light">Talk to our</span>
              <br />
              <span className="heading-section-title-bold">Sales team</span>
            </h2>
          </div>
          <div className="column c-6">
            <a
              href="mailto:sales@stalliex.in"
              className="button huge w-button"
              contentEditable="false"
              style={{ cursor: 'pointer' }}
            >
              Sales
              <span className="heading-section-title-light">@stalliex.in</span>
            </a>
          </div>
        </div>
      </section>
      <footer id="Footer" className="footer section">
        <div className="container">
          <div className="footer-column">
            <div className="footer-brand">
              <img
                src="/StallieX_Logo-cropped-white.svg"
                alt="StallieX Logo"
                height={"500rem"}
                width={"500rem"}
              />
            </div>
            <a
              href="#"
              className="footer-address"
              contentEditable="false"
              style={{ cursor: 'pointer' }}
            >
              Jabalpur, Madhya Pradesh, India
            </a>
            <div className="footer-address">
              <div className="footer-address-text">
                Tel:{' '}
                <a
                  href="#"
                  className="footer-inline-link"
                  contentEditable="false"
                  style={{ cursor: 'pointer' }}
                >
                  +91 8839412424
                </a>
                <br />
                E-mail:{' '}
                <a
                  href="#"
                  className="footer-inline-link"
                  contentEditable="false"
                  style={{ cursor: 'pointer' }}
                >
                  info@stalliex.in
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
